import React from "react";
import HeaderMenuBS from "../components/HeaderMenuBS"
import Banner from "../containers/HomePage1/Banner"
import Clients from "../containers/HomePage1/Clients"
import FeaturedServices from "../containers/HomePage1/FeaturedServices"
import About from "../containers/HomePage1/About"
import OurServices from "../containers/HomePage1/OurServices"
import WhyChooseUs from "../containers/HomePage1/WhyChooseUs"
import Pricing from "../containers/HomePage1/Pricing"
import Testimonials from "../containers/HomePage1/Testimonials"
import Blog from "../containers/HomePage1/Blog"
import Footer from "../components/Footer"
import SEO from "../components/seo";
import Layout from "../components/layout";

const IndexPage = () => (
   <Layout>
       <SEO
            title="Beryl - Business, Corporate Gatsbyjs Website | Gatsbyjs Website Themes"
            description="Buy Responsive Business and Corporate Themes built with GatsbyJS, React Bootstrap and Styled-Components"
        />
        <HeaderMenuBS/>
        <Banner/>
        <Clients/>
        <FeaturedServices/>
        <About/>
        <OurServices/>
        <WhyChooseUs/>
        <Pricing/>
        <Testimonials/>
        <Blog/>
        <Footer/>
   </Layout>
)

export default IndexPage;
